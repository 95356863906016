.load {
	position: sticky;
	z-index: 1;
	height: 85vh;
	display: none;
}

.load_loader {
	display: block;
	/* background: rgba(95,95,95, .5); */
	position: absolute;
	z-index: 100;
	width: inherit;
	height: 100%;
}

.icon {
    height: 20px;
    width: 20px;
}

.logo {
	width: auto;
	height: 2rem;
}

.cColor {
	width: 2rem;
	height: 2rem;
	border: 1px solid #92A0B5;
}

.pagination {
	display: flex;
	margin-top: 2rem;
}
.pagination ul {
	display: flex;
	margin: auto 1rem auto 0;
}
.pagination ul li {
	background-color: #fff;
	height: 1.6rem;
	width: 100%;
	/* border: 1px solid #e3e3e3; */
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.pagination ul li a {
	background-color: transparent;
	height: 1.6rem;
	width: 100%;
	border: 1px solid #e3e3e3 !important;
	border-radius: 12px !important;
	display: flex;
	align-items: center;
}

.noOrganization {
	position: absolute;
	width: 120%;
	height: 64px;
	border: 1px solid var(--red-color);
	border-radius: 10px;
}
.noOrganization + svg {
	position: absolute;
	bottom: -64px;
	z-index: 100;
	color: var(--red-color);
	-webkit-animation: slide-in-bottom 2s ease-in-out infinite both;
	        animation: slide-in-bottom 2s ease-in-out infinite both;
}

 @-webkit-keyframes slide-in-bottom {
	0% {
	  -webkit-transform: translateY(32px);
			  transform: translateY(32px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	  opacity: 1;
	}
  }
  @keyframes slide-in-bottom {
	0% {
	  -webkit-transform: translateY(32px);
			  transform: translateY(32px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	  opacity: 1;
	}
  }

