/*   color variables */
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;

.form__motion {
	position: relative;
	width: 100%;
	height: 1.5rem;
	margin-bottom: 3rem;
	display: block;

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: inherit;
		border: 2px solid $clr-gray400;
		border-radius: var(--radius);
		font-family: inherit;
		font-size: inherit;
		color: #000000;
		outline: none;
		padding: 0.75rem;
		background: none;

		&:hover {
			border-color: var(--primary-color);
		}

		&:focus {
			border-color: var(--primary-color);
		}
	}

	label {
		position: absolute;
		left: 1rem;
		top: 1.75rem;
		padding: 0 1.5rem 0 0.25rem;
		color: var(--secondary-color);
		cursor: text;
		transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
		// background-color: transparent;
		background-color: #fafafa;
		white-space: nowrap;
		overflow: hidden;
		// width: 92%;
		width: auto;
		text-overflow: ellipsis;

		span {
			color: var(--primary-color);
		}
	}

	span.form__desc {
		position: absolute;
		top: 4.2rem;
		left: 1.5rem;
		color: var(--secondary-color);
	}
}
.form__motion__textarea {
	position: relative;
	width: 100%;
	height: 8rem;
	margin-top: 1rem;
	margin-bottom: unset;
	display: block;

	textarea {
		position: absolute;
		top: 0;
		left: 0;
		width: -webkit-fill-available;
		height: auto;
		border: 2px solid $clr-gray400;
		border-radius: var(--radius);
		font-family: inherit;
		font-size: inherit;
		color: #000000;
		outline: none;
		padding: 0.75rem;
		background: none;

		&:hover {
			border-color: var(--primary-color);
		}

		&:focus {
			border-color: var(--primary-color);
		}
	}

	label {
		position: absolute;
		left: 1rem;
		top: 0.75rem;
		padding: 0 0.5rem;
		color: var(--secondary-color);
		cursor: text;
		transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
		background-color: #ffffff;

		span {
			color: var(--primary-color);
		}
	}

	span.form__desc {
		position: absolute;
		top: 3rem;
		left: 1.5rem;
		color: var(--secondary-color);
	}
}
span.form__desc__error {
	position: relative;
	font-size: 0.8rem;
	padding-left: 0.85rem;
	top: -0.5rem;
	color: var(--red-color);
}

/*
1. When the input is in the focus state
reduce the size of the label and move upwards

2. Keep label state when content is in input field
*/

.eye {
	width: 21px;
	height: 18px;
	z-index: 1;
	position: absolute;
	top: 1.95rem;
	right: 1rem;
	color: var(--primary-color);
}

.eye-black {
	color: #000000;
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
	top: 0.25rem;
	font-size: 0.8rem;
	left: 0.8rem;
}

.form__motion__textarea .form__input:focus ~ .form__label,
.form__motion__textarea .form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
	top: -0.5rem;
	font-size: 0.8rem;
	left: 0.8rem;
}

.isRequired {
	border: 2px solid var(--red-color) !important;
	border-color: var(--red-color) !important;
}

.__isrequired {
	color: var(--red-color) !important;
}
