.subTitle {
    font: bold 24px Roboto, arial, sans-serif;
    margin: 1rem 0 1.5rem 0;
}

.modalLabel p {
    color: var(--secondary-color);
}

/* .modalContent p {

} */

.modalLabelC p {
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
}

.modalContentC input {
    margin-bottom: .5rem;
}