.subTitle {
	font: normal 22px/27px Roboto, arial, sans-serif;
	color: #000000;
}

.modalLabel p {
	font: normal 18px/22px Roboto, arial, sans-serif;
	color: var(--secondary-color);
}
.modalContent p {
	font: 18px/22px Roboto, arial, sans-serif;
	color: #000000;
}

.privacy {
	width: 100%;
	height: auto;
	position: relative;
}
.privacy label > span {
	width: 10%;
}
.privacy label > a {
	width: 90%;
}

.privacy a {
	color: inherit;
}

input.cKanim {
	height: 0;
	width: 0;
	display: none;
}

input.cKanim + label {
	width: 100%;
	position: relative;
	display: flex;
	margin: 0.6em 0;
	align-items: center;
	color: #9e9e9e;
	transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
input.cKanim + label > ins {
	position: absolute;
	display: block;
	bottom: 0;
	left: 2.2em;
	height: 0;
	width: 100%;
	overflow: hidden;
	text-decoration: none;
	transition: height 300ms cubic-bezier(0.4, 0, 0.23, 1);
}
input.cKanim + label > ins > i {
	position: absolute;
	bottom: 0;
	font-style: normal;
	color: var(--primary-color);

	width: 90%;
}
input.cKanim + label > span {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1em;
	margin-top: -1.4rem;
	width: 1.1em;
	height: 1.1em;
	background: transparent;
	border: 2px solid #9e9e9e;
	border-radius: 2px;
	cursor: pointer;
	transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

input.cKanim + label:hover,
input.cKanim:focus + label {
	color: var(--primary-color);
	/* font-weight: bold; */
}
input.cKanim + label:hover > span,
input.cKanim:focus + label > span {
	background: rgba(255, 255, 255, 0.1);
}
input.cKanim:checked + label > ins {
	height: 100%;
	/* width: auto; */
}

input.cKanim:checked + label > span {
	border: 0.6em solid var(--primary-color);
	animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
input.cKanim:checked + label > span:before {
	box-sizing: unset;
	user-select: none;
	content: '';
	position: absolute;
	top: 0.6em;
	left: 0.25em;
	border-right: 3px solid #fff;
	border-bottom: 3px solid #fff;
	transform: rotate(45deg);
	transform-origin: 0% 100%;
	animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
	0% {
		transform: scale(1);
	}
	33% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes checkbox-check {
	0% {
		width: 0;
		height: 0;
		border-color: #fff;
		transform: translate3d(0, 0, 0) rotate(45deg);
	}
	33% {
		width: 0.2em;
		height: 0;
		transform: translate3d(0, 0, 0) rotate(45deg);
	}
	100% {
		width: 0.2em;
		height: 0.5em;
		border-color: #fff;
		transform: translate3d(0, -0.5em, 0) rotate(45deg);
	}
}
