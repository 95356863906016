.login {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentLogin {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: auto;
}

.contentLogin h1 {
    margin-bottom: 1.5rem;
}

.contentLogin span {
    margin-bottom: 1rem;
}
.contentLogin input {
	margin: 1rem 0;
	padding: 0.75rem;
}

.link {
    margin: 1rem auto;
    text-align: center;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.signIn {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--primary-color);
    border-radius: var(--radius);
    margin-bottom: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
}
a.signIn:hover {
    color: #ffffff;
}

a.signIn:hover {
    color: #ffffff;
}

.register {
    background: #ffffff;
    color: #000000;
    border: 2px solid var(--primary-color);
    margin-top: 1rem;
}

a.register:hover {
    color: #000000;
}