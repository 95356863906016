.wx10 {
  width: 10%;
}
.wx20 {
  width: 20%;
}
.wx30 {
  width: 30%;
}
.wx40 {
  width: 40%;
}
.wx50 {
  width: 50%;
}
.wx60 {
  width: 60%;
}
.wx70 {
  width: 70%;
}
.wx80 {
  width: 80%;
}
.wx90 {
  width: 90%;
}
.wx100 {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.dc-flex {
  display: flex;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.tab-list-active {
  background-color: white;
  /* border: solid #ccc; */
  border-width: 0 0 1px 0;
  border-bottom: 2px solid var(--primary-color);
}
