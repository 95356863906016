.header {
	width: 100%;
	min-height: 80px;
	background: #476ead 0% 0% no-repeat padding-box;
	position: relative;
}
.headerSuper {
	min-height: 96px;
}

.contentHeader {
	padding: 0.75rem 1.5rem;
	display: flex;
	width: auto;
	height: auto;
}

.headerTextContent > span,
.headerTextContent > p {
	font: 16px Roboto, arial, sans-serif;
	color: #ffffff;
	text-align: center;
}
.headerTextContent > span:first-child {
	font: 700 16px Roboto, arial, sans-serif;
}

.userDetail {
	width: 21%;
	display: flex;
	flex-direction: column;
}

.userDetail p {
	margin: 0 0 0 0;
}

.userDetail .userContent {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.userContent label {
	width: 50%;
	color: #ffffff;
	font-weight: bold;
}

.user {
	font: bold 26px/32px Roboto, arial, sans-serif;
	color: #ffffff;
}

.userNo {
	font: normal 18px/24px Roboto, arial, sans-serif;
	color: #ffffff;
}

.linkTitle {
	margin: auto 0 auto .5rem;
	font: bold 26px/32px Roboto, arial, sans-serif;
	color: #ffffff;
	display: flex;
	align-items: center;
}
.linkTitle svg{
	cursor: pointer;
}

.language {
	font: normal 20px/24px Roboto, arial, sans-serif;
	color: #ffffff;
	margin: auto 2rem auto auto;
}

.btnLang {
	border: none;
	padding: 0;

	background: transparent;
	padding-right: 8px;
	text-transform: uppercase;
	border-right: 1px solid var(--hairline-color);
}
.btnLang:not(:first-child) {
	padding-left: 8px;
}
.btnLang:hover {
	background-color: var(--hairline-color);
}
.btnLangActive {
	color: var(--primary-color);
	font-weight: 600;
}
.btnContentLang {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
}
.btnContentLang > span {
	font: 12px Roboto, arial, sans-serif;
	margin-bottom: 4px;
}

.logout {
	width: auto;
	height: 52px;
	border: 3px solid #ffffff;
	border-radius: 28px;
	opacity: 1;
	margin: auto 0;
	display: flex;
	align-items: center;
	padding: 0px 1.75rem;
	color: #ffffff;
	cursor: pointer;
}

.orglist {
	width: 75%;
	height: 100%;
	position: relative;
	background-color: #476ead;
	color: #fff;
	border: unset;
	cursor: pointer;
}
.orglist:disabled {
	cursor: not-allowed;
}
.orglist:focus-visible {
	outline: none;
}
