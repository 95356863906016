.sidebar {
  width: 21%;
  min-height: calc(100vh - 92px);
  height: 100%;
  position: relative;
  display: block;
  /* padding: 0 0 0 .5rem; */
}

.contentSidebar {
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.link {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.5rem;
  font: normal 18px/32px Roboto, arial, sans-serif;
  color: #000000;
  margin-bottom: .5rem;
}

.link,
.link:hover {
  text-decoration: none;
}

.link svg,
.link img {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}

.link span{
  margin-left: .5rem;
  font-size: 10px;
  font-weight: bold;
}

.separator {
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 1rem;
}

/* SVG */

.a {
  fill: #fff;
  stroke: #5f5f5f;
  stroke-linecap: round;
  stroke-width: 2px;
}

.b {
  fill: #5f5f5f;
}

.c {
  fill: #5f5f5f;
  stroke: #5f5f5f;
  stroke-width: 7px;
}

.d {
  fill: #5f5f5f;
}

.active {
  /* font-weight: bold; */
  background: rgb(71, 110, 173, .1);
}

.active .a {
  stroke: var(--primary-color);
}

.active .b, .active .d {
  fill: var(--primary-color);
}

.active .c {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}
.active .d {
  color: var(--primary-color);
}