.templateTitle {
	font: normal 30px/32px Roboto, arial, sans-serif;
	color: #000000;
}

.dropzone {
	width: 176px;
	height: 176px;
	color: var(--primary-color);
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 4px solid var(--primary-color);
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	/* margin-bottom: 2rem; */
}

.dropzone p {
	text-align: center;
}

.thumb {
	display: inline-flex;
	border-radius: 2px;
	border: 1px solid #eaeaea;
	margin-bottom: 8;
	margin-right: 8;
	width: 100%;
	height: 100%;
	padding: 4px;
	box-sizing: border-box;
}

.thumbInner {
	display: flex;
	min-width: 0;
	overflow: hidden;
}

.thumbAction {
	display: flex;
	margin: .5rem 0 2rem 104px;
}
.thumbAction svg {
	border-radius: 6px;
	background: #fafafa;
	box-shadow: 8px 8px 16px #e1e1e1, 0px 0px 16px #f1f1f1;
	padding: 0.5rem;
	width: auto;
	height: auto;
}

.contentPicker {
	display: flex;
	margin-right: 2rem;
	font: normal 22px/27px Roboto, arial, sans-serif;
	color: #5f5f5f;
}

.img {
	display: block;
	width: auto;
	height: 100%;
}

.colorPicker {
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid var(--hairline-color);
}

.picker {
	margin-left: 2rem;
}

.cover {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.swatch {
	padding: 2px;
	background: #fff;
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
}

.popover {
	position: absolute;
	z-index: 2;
}

.boxColor {
	width: 50px;
	height: 50px;
	border-radius: 2px;
	border: 1px solid var(--hairline-color);
}

.action {
	display: flex;
	justify-content: space-between;
}
