.register {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.register h1 {
  margin-bottom: 2rem;
}

.contentRegister {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.content {
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content input {
  margin: 1rem 0;
  padding: 0.75rem;
}

.content hr {
  margin: 1.5rem 0 1rem 0;
}

.contentRegister .content:last-child hr {
  color: transparent;
}

/* .contentName {
}
.privacy {
} */

input.cKanim {
  height: 0;
  width: 0;
}

input.cKanim + label {
  position: relative;
  display: flex;
  margin: 0.6em 0;
  align-items: center;
  color: #9e9e9e;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
input.cKanim + label > ins {
  position: absolute;
  display: block;
  bottom: 0;
  left: 2.1em;
  height: 0;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  transition: height 300ms cubic-bezier(0.4, 0, 0.23, 1);
}
input.cKanim + label > ins > i {
  position: absolute;
  bottom: 0;
  font-style: normal;
  color: var(--primary-color);
}
input.cKanim + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 1.1em;
  height: 1.1em;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.privacy a {
  color: inherit;
}

input.cKanim + label:hover,
input.cKanim:focus + label {
  color: var(--primary-color);
  /* font-weight: bold; */
}
input.cKanim + label:hover > span,
input.cKanim:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}
input.cKanim:checked + label > ins {
  height: 100%;
}

input.cKanim:checked + label > span {
  border: 0.5em solid var(--primary-color);
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
input.cKanim:checked + label > span:before {
  box-sizing: unset;
  user-select: none;
  content: "";
  position: absolute;
  top: 0.6em;
  left: 0.2em;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #fff;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}

.action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-top: 1px solid var(--secondary-color);
  padding: 1rem 0 0 0;
}

.btnCancel {
  background: #ffffff;
  border: 2px solid var(--primary-color);
  color: #000000;
}

.isRegister {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.isRegister a {
  margin: 2rem 0 0 auto;
}

.isRegister a:hover {
  color: var(--primary-color);
}
