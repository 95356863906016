/* .table {
    max-width: 80%;
    margin-inline: auto;
} */
.imgWrapper {
    width: 100%;
    height: 96px;
}
.imgWrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.pagination {
	display: flex;
	margin-top: 2rem;
}
.pagination ul {
	display: flex;
	margin: auto 1rem auto 0;
}
.pagination ul li {
	background-color: #fff;
	height: 1.6rem;
	width: 100%;
	/* border: 1px solid #e3e3e3; */
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.pagination ul li a {
	background-color: transparent;
	height: 1.6rem;
	width: 100%;
	border: 1px solid #e3e3e3 !important;
	border-radius: 12px !important;
	display: flex;
	align-items: center;
}
