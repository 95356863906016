.modalContent {
	position: relative;
	margin-bottom: 1rem;
}
.dropzone {
	width: 100%;
	height: 176px;
	background: #e0e0e0 0% 0% no-repeat padding-box;
	border: 2px solid var(--secondary-bg);
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.dropzoneImgSlider {
	height: 334px;
}
.content input {
	margin: 1rem 0;
	padding: 0.75rem;
}
.thumb {
	position: relative;
}
.thumb,
.thumb img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.thumb svg {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	z-index: 1;
	width: 1.5rem;
	height: 1.5rem;
}
.imgFileName {
    margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
