.subTitle {
  font: normal 22px/27px Roboto, arial, sans-serif;
  color: #000000;
}

.modalLabel p {
  font: normal 18px/22px Roboto, arial, sans-serif;
  color: var(--secondary-color);
}
.modalContent p {
  font: 18px/22px Roboto, arial, sans-serif;
  color: #000000;
}
