.logo {
    width: auto;
    height: auto;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
}

.logo span {
    font: normal 30px/37px Roboto, arial, sans-serif;
    color: var(--secondary-color);
}

.lang {
    width: auto;
    height: auto;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
}
.lang select{
    border: none;
}
.lang select:focus-visible {
    outline: none;
}