.filterTable {
	/* display: flex;
	flex-wrap: wrap;
	justify-content: space-between; */
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 70% 30%;
}
.filterTable select {
	width: 152px;
	height: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #d8d8d8;
	border-radius: 20px;
	padding: 0 0.75rem;
}
.filterTable select:focus-visible {
	outline: none;
}
.filterHtml {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
	margin-top: 1rem;
	/* align-items: center; */
}
.filterHtml label {
	margin-right: 1rem;
}
.filterSet {
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
}
.ftSelected {
	/* width: 5rem; */
	/* height: auto; */
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	padding: 0.25rem 0.5rem;
	border: 1px solid #d8d8d8;
	border-radius: 3px;
}
.ftSelected svg {
	margin-left: 0.7rem;
	cursor: pointer;
}

.statusDefault {
	color: var(--cyan-color);
}

.statusExpired {
	color: var(--red-color);
}

.statusNotActivated {
	color: var(--yellow-color);
}

.statusNotActivated {
	color: var(--yellow-color);
}

.pagination {
	display: flex;
	margin-top: 2rem;
}
.pagination ul {
	display: flex;
	margin: auto 1rem auto 0;
}
.pagination ul li {
	background-color: #fff;
	height: 1.6rem;
	width: 100%;
	/* border: 1px solid #e3e3e3; */
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.pagination ul li a {
	background-color: transparent;
	height: 1.6rem;
	width: 100%;
	border: 1px solid #e3e3e3 !important;
	border-radius: 12px !important;
	display: flex;
	align-items: center;
}
