.reset {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reset h1 {
  margin-bottom: 2rem;
}

.contentReset {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.btnReset {
  width: 100%;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.btnSignIn {
  width: 100%;
  background: #ffffff;
  color: #000000;
  margin-top: 1rem;
  text-transform: uppercase;
}

.isResetPassword {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.isResetPassword svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 2rem;
}

.isResetPassword a {
  margin: 2rem 0 0 auto;
}

.isResetPassword a:hover {
  color: var(--primary-color);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content input {
  margin: 1rem 0;
  padding: 0.75rem;
}
