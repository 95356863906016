@font-face {
  font-family: "Roboto";
  src: url("./assets/font/roboto/Roboto-Regular.woff") format("woff"),
    url("./assets/font/roboto/Roboto-Regular.woff2") format("woff2"),
    url("./assets/font/roboto/Roboto-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "RobotoBold";
  src: url("./assets/font/roboto/Roboto-Bold.woff") format("woff"),
    url("./assets/font/roboto/Roboto-Bold.woff2") format("woff2"),
    url("./assets/font/roboto/Roboto-Bold.ttf") format("ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(174, 170, 165, 1);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 146, 146, 1);
}

div,
p,
span,
label,
button,
li,
ul {
  font-family: "Roboto", arial, sans-serif;
}
