:root {
	/* Color */
	--primary-background: #fafafa;

	--main-bg: #476ead;
	--secondary-bg: #4a6da5;

	--primary-bg: rgb(174, 170, 165, 0.5);
	--primary-color: #17438b;
	--secondary-color: #929292;
	--hairline-color: rgb(146, 146, 146, 0.3);

	--red-color: #e53935;
	--cyan-color: #38bb9c;
	--yellow-color: #eb910b;
	/* typography */
	--defaultFontSize: 14px;
	--bodyFont: 'RobotoRegular', arial, sans-serif;

	/* rest */
	--backgroundColor: var(--primary-bgb);
	/* --backgroundColor: transparent; */
	--textColor: var(--primary-b1);
	--borderRadius: 0.25rem;
	--letterSpacing: 1px;
	--transition: 0.3s ease-in-out all;

	--radius: 0.5rem;
}

.pac-container {
	border-top: unset !important;
	z-index: 1100 !important;
}

body {
	background: var(--primary-background);
}

#root {
	background: var(--primary-background);
}

.content-tooltip {
	/* z-index: 1000; */
	margin: 0 0.25rem !important;
}
.content-tooltip .tooltip-inner {
	background-color: #476ead;
}

.main-section {
	width: 79%;
}

.main-section-md {
	width: 60%;
	margin: 2rem 3rem;
}
.main-section-l {
	width: 70%;
	margin: 2rem 3rem;
}

.section-loader {
	width: 100%;
	min-height: calc(100vh - 92px);
	height: 100%;
	display: flex;
	align-items: center;
}

.content-section {
	width: 100%;
	min-height: 83%;
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 1.5rem 0;
	padding: 0 2rem;
}

.content-section label {
	font-size: 1rem;
}

.content-section .page-item.active .page-link {
	background: #476ead;
}

.html-step {
	width: 100%;
}

.title-section {
	font: normal 30px/37px Roboto, arial, sans-serif;
	color: #000000;
}

.btn-bim {
	width: fit-content;
	height: 3rem;
	background: var(--primary-color) 0% 0% no-repeat padding-box;
	border: 3px solid var(--primary-color);
	border-radius: 10px;
	padding: 0.25rem 0.75rem;

	display: flex;
	justify-content: center;
	align-items: center;

	font: normal 22px/27px Roboto, arial, sans-serif;
	color: #ffffff;
	cursor: pointer;
}

.btn-bim.uppercase {
	text-transform: uppercase;
}

.btn-bim-white {
	background: #ffffff;
	color: #000000;
}

.btn-bim.small {
	min-height: 2.5rem;
	height: fit-content;
	text-align: center;
	font: normal 18px Roboto, arial, sans-serif;
	border: 2px solid var(--primary-color);
}

.btn-bim.blue {
	text-transform: uppercase;
	background: var(--primary-color);
	color: #ffffff;
	border: 2px solid var(--primary-color);
}

.btn-bim.red {
	text-transform: uppercase;
	background: var(--red-color);
	color: #ffffff;
	border: 2px solid var(--red-color);
}
.btn-bim:disabled {
	opacity: .7;
	cursor: not-allowed;
}
.btn-bim.uppercase {
	text-transform: uppercase;
}
.bim-pd-btn {
	padding: 0 1.75rem;
}

.bim-not-allowed {
	opacity: 0.4;
	cursor: not-allowed !important;
}

a,
a:hover {
	text-decoration: none !important;
	color: initial;
}

.is-loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.toast-notification {
	box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px,
		rgb(0 0 0 / 9%) 0px -3px 5px;
}

.load-loader {
	display: block;
	position: absolute;
	z-index: 100;
	width: inherit;
	height: 100%;
}

.dropdownCustom {
	height: 3rem;
	margin: 1rem 0;
	border: 2px solid #767b91 !important;
	border-radius: var(--radius) !important;
	background: none;
}
.dropdownError {
	border: 2px solid var(--red-color) !important;
}
.dropdownCustom > div:nth-child(1) > span {
	margin: 1rem 0;
	padding: 0.75rem 0.75rem 0.75rem 0.5rem;
}
/* .dropdownCustom > div:nth-child(3) {
	top: 48px;
} */
.dropdownCustom > div:nth-child(1) > input {
	/* width: 100%; */
	width: auto;
}
.dropdownActive > div:nth-child(1) > input {
	width: 0;

	height: 0;
	padding: 0;
}
.dropdownCustom input::placeholder {
	font-size: 1rem;
	color: var(--secondary-color);
	cursor: text;
	transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
	background-color: #ffffff;
}
.dropdownActive .react-dropdown-select-type-multi {
	height: 80%;
}
.dropdownActive > div.react-dropdown-select-type-multi > span {
	margin: 0 0.5rem 0 0;
	padding: 0.25rem 0.75rem 0.25rem 0.5rem;
	height: 100%;
}
.dropDownSelectMulti {
	height: 100%;
}
.dropDownSelectMulti > .react-dropdown-select-type-multi > input {
	margin: unset;
}
.dropDownSelectMulti > .react-dropdown-select-type-multi > span {
	margin: 0.25rem 0.5rem 0.25rem 0 !important;
	padding: 0.25rem 0.75rem 0.25rem 0.5rem;
	height: 100%;
}

.dropdown-d > div > .dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--hairline-color);
}
.dropdown-d > button::after {
  display: none !important;
}
.dropdown-d > button.dropdown-toggle,
.dropdown-d > button.dropdown-d-toggle:active {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-d-toggle {
  width: 100%;
  height: 100%;
}
.dropdown-d > div > a.dropdown-item:active {
	background-color: transparent;
}

div.css-319lph-ValueContainer {
	padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}
div.css-g1d714-ValueContainer {
	padding: 0 0.5rem 0 0;
}
div.css-1pahdxg-control,
div.css-1s2u09g-control {
	border: 0;
}
div.css-1pahdxg-control {
	padding: 0.35rem 0.5rem 0.35rem 0.5rem;
}
div.css-1pahdxg-control:hover {
	border-color: var(--main-bg);
}
div.css-1s2u09g-control {
	padding: 0.35rem 0.5rem 0.35rem 0.5rem;
}
div.css-1rhbuit-multiValue {
	height: 2rem;
	align-items: center;
}

.pagination {
	display: flex;
	align-items: center;
	margin-top: 2rem;
	font: 14px Roboto, arial, sans-serif;
	color: #000;
}
.pagination .page {
	background-color: #fff;
	height: 1.5rem;
	width: 5rem;
	border: 1px solid #e3e3e3;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.pagination .page:hover {
	background-color: #e9ecef;
}

.rdt_Pagination {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
}

.rdt_Pagination > div > button {
	background-color: #fff;
	height: 1.5rem;
	width: 5rem;
	border: 1px solid #e3e3e3;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

#g-maps {
	width: '100%';
	height: 40vh;
}

/* MODAL */

.modal-title {
	font: 30px/37px Roboto, arial, sans-serif;
	letter-spacing: 0px;
	color: #000000;
}
.modal-bim button.close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: 0.25em;
	color: #000;

	background: transparent url('../assets/close.svg') 50%/1em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}
.modal-bim button.close span {
	display: none;
	width: 0;
	height: 0;
}
.modal-bim-body {
	position: relative;
	display: flex;
	flex-direction: column;
}
.modal-bim-content,
.modal-bim-header,
.modal-bim-footer {
	position: relative;
	width: 90%;
	margin: auto;
}
.modal-bim-auto {
	width: 100%;
}
/* .modal-bim-auto .modal-content {
	width: auto;
} */
/* .modal-bim-auto .modal-bim-content,
.modal-bim-auto .modal-bim-header,
.modal-bim-auto .modal-bim-footer {
	width: auto;
} */

.rdt_Table {
	margin-top: 1.5rem;
}

.rdt_TableRow {
	border: unset !important;
}
.rdt_TableCol > div > div {
	display: flex;
	height: 100%;
	align-items: center;
	white-space: normal;
	text-align: center;
}
.dVquhG,
.dhZXyT,
.fqjRsv,
.bPHQBa {
	color: var(--main-bg) !important;
	margin-left: 0.5rem;
}

.rdt_TableCol_Sortable {
	display: flex;
	font: normal 18px/22px Roboto, arial, sans-serif;
	color: #929292;
}
.rdt_TableCol_Sortable span {
	color: #17438b;
	font-size: 12px;
	/* color: transparent; */
	/* transform: scale(0.7); */
	/* margin-left: .5rem; */
}

.rdt_TableCell {
	font: normal 18px/22px Roboto, arial, sans-serif;
	padding: 0.5rem 0.75rem;
}
.rdt_TableCell a {
	color: inherit;
}

/* styling only for specific table */
#tblProject .rdt_TableHeadRow > div:nth-child(4) {
	display: flex;
	justify-content: center;
}
#tblProject .rdt_TableBody .rdt_TableRow > div:nth-child(4),
#tblOrganization .rdt_TableHeadRow > div:nth-child(3),
#tblOrganization .rdt_TableHeadRow > div:nth-child(4),
#tblOrganization .rdt_TableHeadRow > div:nth-child(5),
#tblOrganization .rdt_TableBody .rdt_TableRow > div:nth-child(3),
#tblOrganization .rdt_TableBody .rdt_TableRow > div:nth-child(4),
#tblOrganization .rdt_TableBody .rdt_TableRow > div:nth-child(5) {
	display: flex;
	justify-content: center;
}

/* end styling */

@media screen and (min-width: 1920px) {
	#g-maps {
		height: 30vh;
	}
}
@media screen and (min-width: 1366px) and (max-width: 1648px) {
	#g-maps {
		height: 50vh;
	}
}
@media screen and (min-width: 1366px) and (max-width: 1648px) {
	.main-section-md,
	.main-section-l {
		margin: unset;
	}

	.content-section {
		min-height: 75%;
	}
}
