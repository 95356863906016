.emptyProjects {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btnProjects {
    margin-left: auto;
    cursor: pointer;
}

.emptyProjects .btnProjects {
    margin: 1rem auto;
}

.isRequestActivate {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.isRequestActivate button{
    margin-left: auto;
    margin-top: 2rem;
}

/* Table */
.tProjects a{
    color: inherit;
}
.thProjects {
    text-align: left;
    font: normal 18px/22px Roboto, arial, sans-serif;
    color: #929292;
    padding: .5rem .75rem;
    border-bottom: 1px solid #E1E1E1;
}

.thProjects svg {
    color: #17438B;
}

.trHeadProjects {
    background-color: #ffffff;
}

.thContent {
    display: flex;
    font: normal 18px/22px Roboto, arial, sans-serif;
}

.thContent span {
    margin: auto 0 auto 1rem;
    width: 18px;
    height: 18px;
}

.trProjects {
    background-color: #ffffff;
    min-height: 3rem;
    height: 100%;
}

.tdProjects {
    padding: .5rem .75rem;
    font: normal 20px/24px Roboto, arial, sans-serif;
}

.statusDefault {
    color: var(--cyan-color);
}

.statusExpired {
    color: var(--red-color);
}

.statusNotActivated {
    color: var(--yellow-color);
}

.pagination {
	display: flex;
	margin-top: 2rem;
}
.pagination ul {
	display: flex;
	margin: auto 1rem auto 0;
}
.pagination ul li {
	background-color: #fff;
	height: 1.6rem;
	width: 100%;
	/* border: 1px solid #e3e3e3; */
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.pagination ul li a {
	background-color: transparent;
	height: 1.6rem;
	width: 100%;
	border: 1px solid #e3e3e3 !important;
	border-radius: 12px !important;
	display: flex;
	align-items: center;
}
